body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
nav{
  display: flex;
  height: 18vh;
  justify-items: center;
  align-items: center;
}
ul{
  display: flex;
  justify-items: center;
  align-items: center;
  
}
.Head{
  margin-left: 35px;
  font-size: 30px;
  color: black;
}
.Head span{
  font-size: 30px;
  font-weight: 400;
}
.lii{
  padding-left: 80px;
  list-style: none;
  margin-left: 30px;
  font-size: larger;
  font-weight: 500;
  font-size: 25px;
  text-decoration: none;
}
li{
  list-style: none;
  margin-left: 50px;
  font-size: 25px;
  font-weight: 500;
  
}
li a{
  text-decoration: none !important;
  color: black;
}
.h1{
  color: #333333;
  font-size: 45px;
  font-weight: 500;

}
.h11{
  font-size: 90px;
  color: #333333;
  margin-top: -5px;
}
.btn{
  width: 220px;
  height: 65px;
  background-color: #717fe0;
  color: white;
  border-radius: 30px;
  font-size: x-large;
  margin-top: -10px;
  cursor: pointer;
}
.Main{
  margin-left: 60px;
  margin-top: 180px;
  color: #333333;
}
.secFront{
  margin-top: 120px;
  color: #333333;
}
.con{
  margin-left: 60px;
  padding-top: 270px;
}
.card{
  display: flex;
  color: #333333;
  border: 2px solid #E6EEF7;
  width: 500px;
  position: relative;
  margin-left: 50px;
}
.card  {
  width: 100%;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.1);
}
.write{
  padding-left: 60px;
  margin-top: 20px;
  position: absolute;
}
.cardname{
  font-size: 45px;
}
.season{
  font-size: 25px;
  color: #63666A;
  margin-top: -14px;
}
.cardimg{
  width: 500px;
  height: 350px;
}
.Main button{
  cursor: pointer;
}
.Footer{
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  color: black;
  padding-top: 50px;
  height: 8vh;
  width: 100vw;
  font-size: x-large;
  font-weight: 300;
}
.overview{
  margin-left: 60px;
}
.over{
  color: #333333;
  font-size: 60px;
}
.nabb{
  color: #333333;
}
.nab{
  margin-left: 0px;
}
.second-ul{
  margin-left: -88px;
}
.accessories1{
  display: flex;
  flex-direction: column;
  margin-right: 45px;
  
}
.accessImg{
  width: 380px;
}
.accessories1  {
  width: 100%;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}

.accessories1:hover  {
  transform: scale(1.1);
}
.accessName{
  color: #63666A;
  font-size: 23px;
  margin-top: 20px;
}
.accessChips{
  color:  #4C4E52;
  font-size: 23px;
  margin-top: 13px;
}
.Butn{
  width: 250px;
  height: 65px;
  background-color: #e6e6e6;
  color: #333333;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
  margin-top: -10px;
  cursor: pointer;
}