@import url('https://fonts.googleapis.com/css?family=Roboto:300');
.App {
  color: white;
  padding-bottom: 30px;
  
}
.nav{
  width: 100vw;
  height: 100vh;
  background-image: url("/public/slide-01.jpg");
  background-repeat: no-repeat;
  background-size: 100vw;
  color: white;
  padding-bottom: 30px;
}
.secFront{
  width: 100vw;
  height: 100vh;
  background-image: url("/public/slide-03.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 30px;
}
.cards{
  display: flex;
  margin-top: 120px;
  margin-left: 45px;
  margin-right: 105px;
}
.access{
  margin-top: 100px;
  margin-left: 65px;
  display: flex;
}
.access2{
  margin-top: 100px;
  margin-left: 65px;
  display: flex;
}
.access3{
  margin-top: 100px;
  margin-left: 65px;
  display: flex;
}
.access4{
  margin-top: 100px;
  margin-left: 65px;
  display: flex;
}
.button{
  width: 100vw;
  text-align: center;
  margin-top: 130px;
}


